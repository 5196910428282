import { getBffClient } from '../client/http';

export const findOneEvent = async (eventId) => {
  try {
    const response = await getBffClient().post(
      `/api/mongodb/events/findOne?eventId=${eventId}`,
    );
    return response.data.event;
  } catch (err) {
    throw new Error(`Error finding event: ${err.response?.data?.error}`);
  }
};

export const findOneElement = async ({ eventId, elementId, elementType }) => {
  try {
    let url = `/api/mongodb/elements/findOne?eventId=${eventId}`;
    if (elementId) url += `&elementId=${elementId}`;
    if (elementType) url += `&elementType=${elementType}`;

    const response = await getBffClient().post(url);

    return response.data.element;
  } catch (err) {
    throw new Error(`Error finding element: ${err.response?.data?.error}`);
  }
};

export const setAttendeeReviewData = async ({
  eventId,
  isAttendeeReview,
  releaseDate,
  isRemindLater,
}) => {
  try {
    let url = `/api/mongodb/events/set-attendee-review-data?eventId=${eventId}`;
    if (isAttendeeReview) url += `&isAttendeeReview=${isAttendeeReview}`;
    if (releaseDate) url += `&releaseDate=${releaseDate}`;
    if (isRemindLater) url += `&isRemindLater=${isRemindLater}`;

    await getBffClient().post(url);
  } catch (err) {
    throw new Error(
      `Error updating attendee review data: ${err.response?.data?.error}`,
    );
  }
};

export const setAssigneeData = async ({
  eventId,
  peopleAssignees,
  hlReelAssignees,
  clipsAssignees,
}) => {
  try {
    let url = `/api/mongodb/events/set-assignee-data?eventId=${eventId}`;
    if (peopleAssignees) url += `&peopleAssignees=${peopleAssignees}`;
    if (hlReelAssignees) url += `&hlReelAssignees=${hlReelAssignees}`;
    if (clipsAssignees) url += `&clipsAssignees=${clipsAssignees}`;

    await getBffClient().post(url);
  } catch (err) {
    throw new Error(
      `Error updating assignee data: ${err.response?.data?.error ?? err.message}`,
    );
  }
};

export const setAttendeePayment = async ({ eventId, elementId }) => {
  try {
    await getBffClient().post(
      `/api/mongodb/elements/saveAttendeePayment?eventId=${eventId}&elementId=${elementId}`,
    );
  } catch (err) {
    throw new Error(
      `Error updating attendee payment: ${err.response?.data?.error ?? err.message}`,
    );
  }
};
