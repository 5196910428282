import React, { useState } from 'react';
import moment from 'moment';
import { Form, FloatingLabel } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { isEmpty, formatDate } from '../../../Utils';

import FormFeedback from '../../../components/form/formFeedback';
import SubmitBtn from '../../../components/submitBtn';

import { setAttendeeReviewData } from '../../../services/api/mongodb';
import { useEventStore } from '../../../stores/event';

const SetDateStep = (props) => {
  const { isReleaseDatePassed } = props;

  const eventId = useParams()?.eventId;

  const currentEvent = useEventStore((state) => state.currentEvent);
  const updateCurrentEvent = useEventStore((state) => state.updateCurrentEvent);

  const releaseDate =
    currentEvent.attendeeReviewData?.releaseDate?.slice(0, 10) ?? '';

  const [date, setDate] = useState(releaseDate);
  const [dateError, setDateError] = useState(null);

  const [isShowDate, setIsShowDate] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const onChangeDate = (e) => {
    let value = e.target.value;
    let now = moment().utc();

    if (moment(value).isSameOrBefore(now, 'day'))
      setDateError(
        'Date cannot be the same as or earlier than the current date',
      );
    else setDateError(null);

    setDate(value);
  };

  const onBtnClick = async () => {
    setIsSaving(true);

    const mills = new Date(date).getTime();

    await setAttendeeReviewData({ eventId, releaseDate: mills })
      .then(async () => {
        setIsShowDate(false);
        await updateCurrentEvent();
      })
      .catch(() => toast.error('Something went wrong. Try again later'))
      .finally(() => setIsSaving(false));
  };

  if (isShowDate || !releaseDate)
    return (
      <React.Fragment>
        <div>
          Set the date for when the automatic analysis of your assets will kick
          off. Once the analysis is finished, we will automatically email all
          attendees with links to their personal review pages.
        </div>

        <FloatingLabel label="Release Date*" className="w-full">
          <Form.Control
            type="date"
            name="releaseDate"
            value={date}
            onChange={onChangeDate}
            placeholder="dd/mm/yyyy"
            isInvalid={!!dateError}
            required
          />
          <FormFeedback error={dateError} />
        </FloatingLabel>

        <div className="text-sm italic">
          Note: Analysis will start at 12 AM UTC
        </div>

        <SubmitBtn
          title="Save"
          onClick={onBtnClick}
          isProcessing={isSaving}
          isDisabled={isEmpty(date) || !!dateError}
          className="w-full"
        />
      </React.Fragment>
    );

  return (
    <React.Fragment>
      <div>
        Release date is set to: <b>{formatDate(releaseDate)}</b>.{' '}
        {!isReleaseDatePassed && (
          <span
            className="underline cursor-pointer text-primary-900 hover:opacity-80"
            onClick={() => setIsShowDate(true)}
          >
            Change date
          </span>
        )}
      </div>
    </React.Fragment>
  );
};

export default SetDateStep;
