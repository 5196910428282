import React, { useState, useEffect } from 'react';
import { PiQuestionFill } from 'react-icons/pi';

import {
  goTo,
  isEmpty,
  isPlural,
  getPluralPhrase,
  getShareLink,
} from '../../Utils';

import { trackAttendeeClickGenerate } from '../../analytics';

import FancyBtn from '../../components/fancyBtn';
import ErrorModal from '../../components/modals/errorModal';
import PageHeader from '../../components/pageHeader';
import PageSpinner from '../../components/pageSpinner';
import PopoverTrigger from '../../components/popoverTrigger';
import HlReelPreview from '../../pages/VideoBuilder/Analysis/components/hlReelPreview';
import ThumbnailsContainer from '../../pages/VideoBuilder/components/thumbnailsContainer';

import { getOneCreation } from '../../services/api/creations';
import { getEventPublic } from '../../services/api/events';
import { getTemplateData } from '../../services/api/template';
import { getAttendeeReviewData } from '../../services/reviewVideoService';

import withRouter from '../../withRouter';

import GenerateModal from './components/generateModal';

const ReviewVideo = (props) => {
  const { faceId } = props.params;

  const [event, setEvent] = useState(null);

  const [person, setPerson] = useState({});

  const [hlReel, setHlReel] = useState(null);
  const [requiredShots, setRequiredShots] = useState([]);

  const [clipSelections, setClipSelections] = useState([]);
  const [selectedClip, setSelectedClip] = useState({});

  const [isCollapsed, setIsCollapsed] = useState(true);

  const [showGenerateModal, setShowGenerateModal] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const onMount = async () => {
      setIsLoading(true);

      await fetchData();

      setIsLoading(false);
    };

    onMount();
  }, [faceId]);

  const fetchData = async () => {
    const { person, eventId } = await getAttendeeReviewData(faceId);

    const event = await getEventPublic(eventId);

    if (isEmpty(person) || isEmpty(event)) {
      setError(
        "We couldn't find your data. Maybe it took a coffee break?<br />Double-check that you’re using the right link, or give it another go later!",
      );
      return;
    }

    // check if creation is already generated
    const creation = await getOneCreation(faceId);
    if (!!creation) {
      goTo(getShareLink(eventId, faceId));
      return;
    }

    const { hasTemplate, videoTemplate, requiredShots } = await getTemplateData(
      eventId,
      true,
    );

    if (!hasTemplate) {
      setError('Looks like the data is still cooking, check back later!');
      return;
    }

    // clip selections – find the matching clips for wildcard spots

    const selectionsObj = {
      faceId: person.face.elementId,
      wildcardsFiller: requiredShots.map((shot) => ({
        wildCardShotIndex: shot.shotIndex,
        clipId: null,
      })),
    };

    const checkedClips = [];

    // find matching clips
    selectionsObj.wildcardsFiller.forEach((w) => {
      const duration = requiredShots.find(
        (shot) => shot.shotIndex === w.wildCardShotIndex,
      )?.duration;

      // find the first clip with highest ranking score that matches the wildcard spot
      const matchingClip = person.clips.find((clip) => {
        return (
          !checkedClips.includes(clip.elementId) &&
          clip.segment.durationMilliseconds / 1000 >= duration
        );
      });

      if (matchingClip) {
        w.clipId = matchingClip.elementId;

        // update array of checked clips ids
        checkedClips.push(matchingClip.elementId);
      }
    });

    person.selectedClips = selectionsObj.wildcardsFiller;

    setEvent(event);
    setPerson(person);
    setHlReel(videoTemplate);
    setRequiredShots(requiredShots);
    setClipSelections(selectionsObj);
  };

  // clear the selected clip when clicked outside of thumbnails container or highlight reel preview
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!isEmpty(selectedClip)) {
        const thumbnailContainerEl = document.getElementById(
          person?.face?.elementId,
        );
        const hlReelPreviewEl = document.getElementById(
          'hl-reel-preview-person',
        );

        if (
          !thumbnailContainerEl?.contains(event.target) &&
          !hlReelPreviewEl?.contains(event.target)
        )
          setSelectedClip({});
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectedClip, person?.face?.elementId]);

  // change selected clip for particular wildcard
  const handleChangeSelection = (wildcard) => {
    let clipSelectionsTemp = { ...clipSelections };

    clipSelectionsTemp.wildcardsFiller.forEach((w) => {
      if (w.clipId === selectedClip.clipId) w.clipId = null;
      else if (w.wildCardShotIndex === wildcard.shotIndex)
        w.clipId = selectedClip.clipId;
    });

    setClipSelections(clipSelectionsTemp);
  };

  return (
    <PageSpinner
      isLoading={isLoading}
      title="Review Your Video"
      noTips
      isPageContainer
      className="gap-4"
    >
      {!isEmpty(person) && !error && (
        <React.Fragment>
          <PageHeader
            heading={
              <div className="flex gap-4 items-center">
                <img
                  className="w-10 h-10 rounded-full object-cover"
                  src={person.img}
                  alt={`${person.face.name} Icon`}
                />

                <div>{person.face.name} – Review your video</div>
              </div>
            }
            subheading={`Craft your perfect video from ${event.name} – because your memories deserve the VIP treatment!`}
          />

          <div>
            Our AI already selected recommended clips for{' '}
            {getPluralPhrase(requiredShots, 'wildcard spot')}, but you can
            re-select these clips{' '}
            <PopoverTrigger
              content={
                <span className="cursor-pointer">
                  <PiQuestionFill className="text-[1.1rem] hover:fill-[url('#icon-primary-gradient')]" />
                </span>
              }
              placement="bottom"
              addClass="big-popover"
              popoverHeader="To re-select a clip:"
              popoverBody={
                <React.Fragment>
                  <div>
                    1. In the list of the clips, click on any of the clips
                  </div>
                  <div>
                    2. Then, in the highlight reel preview click on the wildcard
                    you want that clip to be used for
                  </div>
                  <div>
                    If the wildcard is longer than the clip, you won't be able
                    to select it.
                  </div>
                  <div>
                    <i>Note: refreshing the page will reset your selections.</i>
                  </div>
                </React.Fragment>
              }
            />
          </div>

          <HlReelPreview
            hlReel={hlReel}
            person={person}
            selectedClip={selectedClip}
            handleChangeSelection={handleChangeSelection}
          />

          <div>
            <h5 className="mb-3">
              {person.clips.length === 0
                ? 'No available clips'
                : `Available clip${isPlural(person.clips)} (${person.clips.length})`}
            </h5>

            <ThumbnailsContainer
              groupId={person.face.elementId}
              videos={person.clips}
              isCollapsed={isCollapsed}
              onClickShowMoreBtn={() => setIsCollapsed(!isCollapsed)}
              clipSelections={[clipSelections]}
              selectedClip={selectedClip}
              setSelectedClip={setSelectedClip}
            />
          </div>

          <div className="flex flex-col items-center text-center mt-6 gap-2">
            <div className="text-lg font-bold">
              Ready to generate your personalised highlight reel?
            </div>

            <div className="italic">
              We'll generate reel based on the selections above.
            </div>

            <FancyBtn
              text="Generate"
              isDisabled={
                !clipSelections.wildcardsFiller.every((clip) => clip.clipId)
              }
              onClick={() => {
                setShowGenerateModal(true);
                trackAttendeeClickGenerate();
              }}
            />
          </div>
        </React.Fragment>
      )}

      <GenerateModal
        show={showGenerateModal}
        onHide={() => {
          setShowGenerateModal(false);
        }}
        eventId={event?.eventId}
        faceId={faceId}
        clipSelections={clipSelections}
      />

      <ErrorModal
        show={!!error}
        heading="Something went wrong"
        subheading={error}
        isTryAgainBtn
        isContactUs
      />
    </PageSpinner>
  );
};

export default withRouter(ReviewVideo);
