import React from 'react';
import { Link } from 'react-router-dom';

import { trackDownloadIndividualSamples } from '../../../../analytics';
import { ZIP_URL } from '../../../../constants';

export const getHlReelText = ({
  noHlReel,
  isUploading,
  hlReel,
  uploadingError,
  hasCreations,
  orientation,
  isDemo,
  isPublic,
}) => {
  const heading = 'Upload your highlight reel';
  let subheading = (
    <>
      Your highlight reel should contain wildcard slots with the word "SHOT",
      our AI will substitute these with personalised selections for each person.
      {!isUploading && !isPublic && (
        <div className="mt-3 italic">
          Not sure what to upload?{' '}
          <Link
            to={ZIP_URL.FIFA_TEMPLATE_ZIP}
            onClick={() => trackDownloadIndividualSamples('hl_reel')}
          >
            Try our sample
          </Link>
        </div>
      )}
    </>
  );

  if (isDemo)
    subheading = (
      <>
        <b>
          Use the highlight reel below by clicking on it to load it into your
          project.
        </b>
        <br />
        The highlight reel contains wildcard slots with the word "SHOT", our AI
        will substitute these with personalised selections for each person.
      </>
    );

  const generatedAlertText = hasCreations ? (
    <>You cannot upload a new highlight reel.</>
  ) : null;

  const uploadInfoPoints = [
    'Your wildcard slots have the word "SHOT", on a white background',
    '"SHOT" is in large capitals using Arial or Helvetica in black font',
  ];

  if (!!orientation)
    uploadInfoPoints.push(
      `Your highlight reel is in ${orientation} orientation`,
    );

  const summaryInfoPoints = [
    'Re-watch the uploaded highlight reel to ensure it includes "SHOT" wildcard slots',
  ];

  if (!hasCreations)
    summaryInfoPoints.push(
      "Click 'Re-Upload' to upload a new highlight reel, but note that you won't be able to re-upload after the videos have been generated",
    );

  if (!!orientation)
    summaryInfoPoints.push(
      `Note that your highlight reel will be processed and optimised for ${orientation}`,
    );

  if (uploadingError)
    return {
      heading,
      subheading,
      alertData: {
        variant: 'warning',
        text: (
          <>
            Unfortunately, there was an error when uploading your highlight
            reel. Please try again later.
          </>
        ),
      },
      infoPoints: uploadInfoPoints,
    };

  // no highlight reel
  if (noHlReel) return { heading, subheading, infoPoints: uploadInfoPoints };

  // highlight reel is uploading
  if (isUploading) return { heading, subheading, infoPoints: uploadInfoPoints };

  // highlight reel is uploaded
  if (hlReel)
    return {
      heading: 'Your highlight reel',
      subheading: isDemo
        ? 'Watch the highlight reel below to better understand what wildcard slots are.'
        : null,
      generatedAlertText,
      infoPoints: summaryInfoPoints,
    };

  return {
    heading: null,
    subheading: null,
    alertData: null,
    generatedAlertText: null,
    infoPoints: null,
  };
};
