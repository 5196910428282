import React from 'react';
import { Link } from 'react-router-dom';

import { isEmpty, getPluralPhrase } from '../../../../Utils';

import { trackDownloadIndividualSamples } from '../../../../analytics';

import { ZIP_URL } from '../../../../constants';

// calculate total uploading progress based on each clip progress
const getProgress = (uploadingClips, filesNum) => {
  let progress = 0;

  if (!isEmpty(uploadingClips)) {
    uploadingClips.forEach((clip) => {
      progress += clip.progress / filesNum;
    });

    progress = Math.round(progress);
  }

  return progress;
};

export const getClipsText = ({
  noClips,
  uploadingClips,
  finishedClips,
  filesNum,
  hasCreations,
  orientation,
  isDemo,
  handleUploadMore,
  demoData,
  isPublic,
}) => {
  const heading = 'Upload your clips';
  const subheading = (
    <>
      {isDemo && (
        <>
          <b>
            Use the assets below by clicking on{' '}
            {demoData.isLimited ? 'all the clips' : 'the clips you want'} to
            load them into your project.
          </b>
          <br />
        </>
      )}
      Clips are close up videos of people, our AI will pick the best ones to use
      for your Highlight Reel.
      {!isDemo && isEmpty(uploadingClips) && !isPublic && (
        <div className="mt-3 italic">
          Not sure what to upload?{' '}
          <Link
            to={ZIP_URL.FIFA_SELECTS_ZIP}
            onClick={() => trackDownloadIndividualSamples('clips')}
          >
            Try our samples
          </Link>
        </div>
      )}
    </>
  );

  const tipText = (
    <div>Try uploading in small batches to avoid any issues.</div>
  );

  const generatedAlertText = hasCreations ? (
    <>
      If you upload new clips, it will only be used in videos for new people.
      All generated videos will remain the same.
    </>
  ) : null;

  const uploadInfoPoints = ['Your clips contain close-up footage of people'];

  if (!!orientation)
    uploadInfoPoints.push(`Your clips are in ${orientation} orientation`);

  const summaryInfoPoints = [
    'Move your cursor over a clip to preview it',
    "Move your cursor over a clip and click the 'X' button to delete it",
    "Click 'Upload More' to add new clips at any time",
  ];

  if (!!orientation)
    summaryInfoPoints.push(
      `Note that your clips will be processed and optimised for ${orientation}`,
    );

  // no clips
  if (noClips)
    return {
      heading,
      subheading,
      tipText,
      generatedAlertText,
      infoPoints: uploadInfoPoints,
    };

  const progress = getProgress(uploadingClips, filesNum);

  // clips are uploading
  if (!isEmpty(uploadingClips))
    return {
      heading,
      subheading,
      progress,
      tipText,
      infoPoints: uploadInfoPoints,
    };

  // clips are uploaded
  if (!isEmpty(finishedClips))
    return {
      heading: 'Your clips',
      subheading: (
        <>
          {getPluralPhrase(finishedClips, 'clip')} currently uploaded.{' '}
          {!isDemo && (
            <span
              className="text-primary-900 underline cursor-pointer hover:opacity-80"
              onClick={() => handleUploadMore('Top link')}
            >
              Upload more
            </span>
          )}
        </>
      ),
      generatedAlertText,
      progress,
      infoPoints: summaryInfoPoints,
    };

  return {
    heading: null,
    subheading: null,
    generatedAlertText: null,
    progress: 0,
    infoPoints: null,
    tipText,
  };
};
