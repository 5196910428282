import React from 'react';
import { PiArrowLeft } from 'react-icons/pi';
import { useNavigate } from 'react-router-dom';

import { useEventStore } from 'stores/event';
import { getVBLinkParts } from '../../../Utils';
import { trackClickBackButton } from '../../../analytics';
import { URL } from '../../../constants';

const BackBtn = () => {
  const currentEvent = useEventStore((state) => state.currentEvent);

  const navigate = useNavigate();

  const handleClick = () => {
    const { eventId, page } = getVBLinkParts(window.location.pathname);

    navigate(
      `/${eventId}${currentEvent.isAssigneeAccess ? URL.VB_ASSIGNEE : URL.VIDEO_BUILDER}`,
    );

    trackClickBackButton(page, currentEvent);
  };

  return (
    <button
      onClick={handleClick}
      className="w-min flex items-center p-2 mb-2 gap-1 rounded-md hover:bg-grey-100"
    >
      <PiArrowLeft size={20} />
      <div className="uppercase">Back</div>
    </button>
  );
};

export default BackBtn;
