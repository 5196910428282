import React from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet-async';

import { getIsShowSideNav, getDefaultTips } from '../Utils';

import LogoImage from '../assets/cc-logo-spinner.png';

import Error from '../pages/General/error';

import { useUserStore } from '../stores/user';

import ChangingTip from './changingTip';
import PageContainer from './pageContainer';

const PageSpinner = ({
  isLoading,
  pageError,
  title,
  description,
  children,
  noTips,
  isFullPage,
  isPageContainer,
  className,
  customTips,
}) => {
  const user = useUserStore((state) => state.user);
  const accessToken = useUserStore((state) => state.accessToken);

  const logoVariants = {
    initial: {
      scale: 1,
      opacity: 0.48,
    },
    animate: {
      scale: [1, 0.9, 0.9, 1, 1],
      opacity: [1, 0.48, 0.48, 1, 1],
    },
  };

  const innerBorderVariants = {
    initial: {
      scale: 1.2,
      rotate: 270,
      opacity: 0.25,
      borderRadius: '25%',
    },
    animate: {
      scale: [1.2, 1, 1, 1.2, 1.2],
      rotate: [270, 0, 0, 270, 270],
      opacity: [0.25, 1, 1, 1, 0.25],
      borderRadius: ['25%', '25%', '50%', '50%', '25%'],
    },
  };

  const outerBorderVariants = {
    initial: {
      scale: 1.2,
      rotate: 270,
      opacity: 0.35,
      borderRadius: '25%',
    },
    animate: {
      scale: [1, 1.2, 1.2, 1, 1],
      rotate: [0, 270, 270, 0, 0],
      opacity: [1, 0.25, 0.25, 0.25, 1],
      borderRadius: ['25%', '25%', '50%', '50%', '25%'],
    },
  };

  const tipsList = customTips ?? [
    'Until upload completes, refrain from clicking the back button or refreshing the page',
    'Until upload completes, ensure you remain connected to the internet',
    "Until upload completes, don't log out or exit your browser",
    'To create a compelling video, use a variety of shots: drone, wide, close up etc',
    'To ensure facial recognition success, use good lighting',
    'You may upload up to <b>3</b> photos for each person',
    ...getDefaultTips().map((tipObj) => tipObj.tip),
  ];

  const { hasSideNav, isOpenSideNav } = getIsShowSideNav(accessToken, user);
  const isShowSideNav = hasSideNav && isOpenSideNav;

  const spinnerClass = classNames({
    'w-[90vw] absolute right-[5vw] flex flex-col items-center justify-center gap-8': true,
    'h-[calc(100vh_-_var(--header-height))] top-[var(--header-height)]':
      !isFullPage,
    'h-screen top-0': isFullPage,
    'sm:w-screen sm:right-0': isFullPage || !isShowSideNav,
    'sm:w-[var(--page-width)] sm:right-[3.5vw]': !isFullPage && isShowSideNav,
  });

  if (isLoading)
    return (
      <div className={spinnerClass}>
        <div className="relative w-full flex items-center justify-center">
          <motion.div
            variants={logoVariants}
            initial="initial"
            animate="animate"
            transition={{
              duration: 2,
              ease: 'easeInOut',
              repeatDelay: 1,
              repeat: Infinity,
            }}
          >
            <motion.div
              style={{ width: 64, height: 64 }}
              initial={{ scale: 1 }}
              animate={{ scale: 1.1 }}
              transition={{
                yoyo: Infinity,
                duration: 2,
                repeatType: 'reverse',
              }}
            >
              <img
                src={LogoImage}
                alt="Company logo"
                style={{ width: '100%', height: '100%' }}
              />
            </motion.div>
          </motion.div>

          <motion.div
            variants={innerBorderVariants}
            className="absolute w-[90px] h-[90px] rounded-[25%] border-3 border-[rgba(240,90,91,0.25)] border-solid"
            initial="initial"
            animate="animate"
            transition={{
              ease: 'linear',
              duration: 3,
              repeat: Infinity,
            }}
          />

          <motion.div
            variants={outerBorderVariants}
            className="absolute w-[110px] h-[110px] rounded-[25%] border-[6px] border-[rgba(220,69,107,0.35)] border-solid"
            initial="initial"
            animate="animate"
            transition={{
              ease: 'linear',
              duration: 3,
              repeat: Infinity,
            }}
          />

          {!noTips && <ChangingTip type="page-spinner" tipsList={tipsList} />}
        </div>
      </div>
    );

  if (!!pageError) return <Error code={pageError} />;

  if (isPageContainer)
    return (
      <PageContainer
        title={title}
        description={description}
        addClass={className}
      >
        {children}
      </PageContainer>
    );
  else
    return (
      <>
        {(title || description) && (
          <Helmet>
            {title && <title>{title} | CrowdClip</title>}
            {description && <meta name="description" content={description} />}
          </Helmet>
        )}

        {children}
      </>
    );
};

export default PageSpinner;
