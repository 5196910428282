import React from 'react';
import { Modal } from 'react-bootstrap';
import { PiCheckCircle } from 'react-icons/pi';

import { goTo } from '../../Utils';
import {
  trackAttendeeConfirmRemoveWatermark,
  trackAttendeeCancelRemoveWatermark,
} from '../../analytics';
import { Button } from '../../components';
import config from '../../config';

const { stripeLinks } = config;

const PaymentModal = (props) => {
  const { show, onHide, event, person, isConfirmation } = props;

  if (!event || !person) return null;

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Body className="flex flex-col items-center px-4 sm:px-8 py-8 gap-4 text-center">
        {isConfirmation ? (
          <React.Fragment>
            <PiCheckCircle color="var(--success-color)" size={100} />

            <h4>Payment successful</h4>

            <div>
              Thanks for upgrading your video. We've now generated
              watermark-free highlight reels for you to share and enjoy.
            </div>

            <Button className="w-full" onClick={onHide}>
              Check Out
            </Button>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <h4>Would you like to remove the watermark for just $55?</h4>

            <div className="w-full flex flex-col sm:flex-row gap-4">
              <Button
                className="w-full sm:w-1/2"
                onClick={() => {
                  trackAttendeeConfirmRemoveWatermark();
                  goTo(
                    `${stripeLinks.removeWatermarkAttendee}?client_reference_id=${event.eventId}_${person.face.elementId}&prefilled_email=${person.face.email}`,
                  );
                }}
              >
                Yes, Remove Watermark
              </Button>

              <Button
                variant="pink"
                className="w-full sm:w-1/2"
                onClick={() => {
                  trackAttendeeCancelRemoveWatermark();
                  onHide();
                }}
              >
                No, Cancel
              </Button>
            </div>
          </React.Fragment>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default PaymentModal;
