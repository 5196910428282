import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { goTo, getShareLink } from '../../../Utils';
import {
  trackClickTakeMeBack,
  trackAttendeeGenerationStartSuccess,
  trackAttendeeGenerationStartError,
  trackAttendeeGenerationFinish,
} from '../../../analytics';

import { Spinner, Button } from '../../../components';
import ChangingTip from '../../../components/changingTip';

import {
  generateCreations,
  getOneCreation,
} from '../../../services/api/creations';

const GenerateModal = (props) => {
  const { show, onHide, eventId, faceId, clipSelections } = props;

  const [isProcessing, setIsProcessing] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);

  // if creation is generating – re-fetch every 10 seconds
  useEffect(() => {
    if (isGenerating) {
      const generationInterval = setInterval(async () => {
        const creation = await getOneCreation(faceId);

        if (!!creation) {
          trackAttendeeGenerationFinish();

          goTo(getShareLink(eventId, faceId));

          setIsProcessing(false);
          setIsGenerating(false);

          clearInterval(generationInterval);
        }
      }, 10000);

      return () => clearInterval(generationInterval);
    }
  }, [eventId, faceId, isGenerating]);

  const onClickGenerate = async () => {
    setIsProcessing(true);

    const data = { eventId, clipSelections: [clipSelections] };

    await generateCreations(data, true)
      .then(() => {
        setIsGenerating(true);
        trackAttendeeGenerationStartSuccess();
      })
      .catch((err) => {
        setIsProcessing(false);
        trackAttendeeGenerationStartError(err.message);
        toast.error(err.message);
        onHide();
      });
  };

  const tipsList = [
    'Your video is being assembled in the cosmic workshop. Just a few more lightyears!',
    'Stitching together your interstellar memories—almost ready for launch!',
    'Your video is orbiting the digital universe and will land shortly',
    'Our space engineers are fine-tuning your video at warp speed. Hold tight!',
    'Creating your masterpiece among the stars. It’ll be worth the wait!',
    'The countdown has begun. Your video is fueling up for the final launch!',
    'Gathering cosmic dust to sprinkle some magic on your video. Almost there!',
    'Your video is passing through a wormhole of creativity. Hang in there!',
    'Our satellites are compiling your video footage from across the galaxy!',
    'Your video is on a mission to become out of this world. Preparing for re-entry!',
  ];

  return (
    <Modal show={show} onHide={() => !isProcessing && onHide()} centered>
      <Modal.Body className="flex flex-col items-center px-4 sm:px-8 py-8 gap-4 text-center">
        {isProcessing ? (
          <React.Fragment>
            <Spinner customSize="w-20 h-20" color="primary" />

            <h4>Generating your video</h4>

            <div>This might take a bit. Feel free to check back later!</div>

            <ChangingTip tipsList={tipsList} />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <h4>Are you sure you want to proceed?</h4>

            <div>
              This is your <b>only chance to generate</b>, so be certain before
              you continue
            </div>

            <div className="w-full flex flex-col sm:flex-row items-center justify-between gap-4">
              <Button className="w-full sm:w-1/2" onClick={onClickGenerate}>
                Yes, Generate
              </Button>

              <Button
                variant="pink"
                className="w-full sm:w-1/2"
                onClick={() => {
                  onHide();
                  trackClickTakeMeBack('attendee_generate');
                }}
              >
                Not Yet, Take Me Back
              </Button>
            </div>
          </React.Fragment>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default GenerateModal;
