import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

import { isEmpty, getShareLink, getDemoSelected } from '../../../Utils';
import { sendStepCompleteEmail } from '../../../UtilsEmail';

import {
  trackUploadDemo,
  trackUploadFileSuccess,
  trackUploadBatchSuccess,
  trackUploadError,
  trackClickUploadMore,
  trackPeopleToggleDetails,
} from '../../../analytics';

import { Switch } from '../../../components';
import DraggerSimplified from '../../../components/draggerSimplifiedNew';
import ErrorModal from '../../../components/modals/errorModal';
import PageHeader from '../../../components/pageHeader';
import PageSpinner from '../../../components/pageSpinner';
import PageSubheader from '../../../components/pageSubheader';
import SellingPoint from '../../../components/sellingPoint';
import UploadInfoPoints from '../../../components/uploadInfoPoints';

import { DEMO_ID } from '../../../constants';

import { getCreationResults } from '../../../services/api/creations';
import { faceMerge, getUploadedFaces } from '../../../services/api/faces';
import { uploadFile } from '../../../services/uploadFileService';

import { useEventStore } from '../../../stores/event';
import { useUserStore } from '../../../stores/user';

import BackBtn from '../components/backBtn';
import DoneBtn from '../components/doneBtn';
import PageWithSideBar from '../components/pageWithSideBar';
import UploadMoreBtn from '../components/uploadMoreBtn';
import DemoComponent from './components/demoComponent';
import PeopleSummary from './components/peopleSummary';
import { getPeopleText } from './components/peopleText';

const People = () => {
  const eventId = useParams()?.eventId;
  const uploadRef = useRef(null);

  const [people, setPeople] = useState([]);
  const [generatedPeopleIds, setGeneratedPeopleIds] = useState([]);

  const [showUpload, setShowUpload] = useState(false);
  const [uploadingPeople, setUploadingPeople] = useState({});
  const [deletingPeople, setDeletingPeople] = useState([]);
  const [attendeeNum, setAttendeeNum] = useState(people.length ?? 0);
  const [isShowDetails, setIsShowDetails] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const [pageError, setPageError] = useState(null);
  const [error, setError] = useState(null);

  // demo data
  const demoData = JSON.parse(localStorage.getItem('demoData')) ?? {};
  const isDemo = useMemo(() => eventId === DEMO_ID.FIFA, [eventId]);
  const [demoPeople, setDemoPeople] = useState(demoData.people ?? []);

  const assigneeData = JSON.parse(localStorage.getItem('assigneeData')) ?? {};

  const user = useUserStore((state) => state.user);
  const currentEvent = useEventStore((state) => state.currentEvent);
  const setEventId = useEventStore((state) => state.setEventId);
  const setDemoEvent = useEventStore((state) => state.setDemoEvent);

  const isPublic = useMemo(
    () => currentEvent?.isAssigneeAccess,
    [currentEvent?.isAssigneeAccess],
  );

  // set event id on mount
  useEffect(() => {
    if (isDemo) setDemoEvent(eventId);
    else setEventId(eventId, true);
  }, [eventId, isDemo, setDemoEvent, setEventId]);

  // fetch data on mount
  useEffect(() => {
    const onMount = async () => {
      setIsLoading(true);

      let pageError = currentEvent.error ?? null;

      if (
        currentEvent.isAssigneeAccess &&
        !assigneeData[currentEvent.eventId]?.accessPages.includes('people')
      )
        pageError = 403;

      if (pageError) setPageError(pageError);
      else {
        if (isDemo) fetchDemoPeopleData();
        else await fetchPeopleData();
      }

      setIsLoading(false);
    };

    if (currentEvent) onMount();
  }, [currentEvent]);

  const fetchPeopleData = async (checkPhotos) => {
    try {
      const people = await getUploadedFaces(eventId, isPublic);
      const generatedPeople = await getCreationResults(eventId, isPublic);

      setPeople(people);
      setGeneratedPeopleIds(
        generatedPeople.map((person) => person.face.elementId),
      );

      if (checkPhotos) {
        const hasAllPhotos = people.every(
          (person) =>
            isEmpty(person.files) ||
            person.files.every((file) => file.mediaStoreCompleted),
        );

        // if some photos are still uploading – re-fetch every second
        if (!hasAllPhotos) setTimeout(() => fetchPeopleData(true), 1000);
      }
    } catch (err) {
      console.error(`Error fetching the people: ${err}`);
      setError('Error fetching the people');
    }
  };

  const fetchDemoPeopleData = () => {
    if (demoData.isPeopleUploaded) {
      const selectedPeople = getDemoSelected(demoPeople);
      const generatedPeopleIds = selectedPeople
        .filter((person) => person.isGenerated)
        .map((person) => person.face.elementId);

      setPeople(selectedPeople);
      setGeneratedPeopleIds(generatedPeopleIds);
    }
  };

  const { newPeople, generatedPeople } = useMemo(() => {
    let newPeople = [];
    let generatedPeople = [];

    people.forEach((person) => {
      if (generatedPeopleIds.includes(person.face.elementId)) {
        person.videoLink = getShareLink(eventId, person.face.elementId);
        generatedPeople.push(person);
      } else newPeople.push(person);
    });

    return { newPeople, generatedPeople };
  }, [eventId, people, generatedPeopleIds]);

  const handleUploadStart = () => {
    setIsUploading(true);
    setShowUpload(false);
  };

  const handleUploadPerson = async (file, fileList) => {
    try {
      handleUploadStart();

      const now = new Date();

      // set uploading people data
      const uploadingPeopleTemp = { ...uploadingPeople };

      fileList.forEach((file) => {
        uploadingPeopleTemp[file.uid] = {
          face: { id: file.uid, name: file.name, created: now },
        };
      });

      setUploadingPeople(uploadingPeopleTemp);

      // set person details
      const parentElementId = uuidv4();
      const index = fileList.indexOf(file);
      const num = attendeeNum + index + 1;

      setAttendeeNum(num);

      const personData = {
        elementId: parentElementId,
        name: `Attendee ${num}`,
        created: now,
        modified: now,
      };

      // upload person image
      uploadFile({ file, eventId, parentElementId, isPublic, onProgress })
        // when upload is complete – create person & fetch data
        .then(async () => {
          await faceMerge(eventId, personData, isPublic);
          fetchPeopleData();
          trackUploadFileSuccess('people', eventId);
        });
    } catch (error) {
      console.error(error);
      trackUploadError('people', file.name);
      toast.error(`${file.name} file upload failed.`);
    }
  };

  const onProgress = (event) => {
    setUploadingPeople((previousPeople) => ({
      ...previousPeople,
      [event.id]: {
        face: {
          ...previousPeople[event.id].face,
          parentElementId: event.parentElementId,
        },
      },
    }));
  };

  const handleUploadMore = (e, position) => {
    setShowUpload(true);

    if (!isDemo)
      setTimeout(() => {
        uploadRef.current?.upload?.uploader?.onClick(e);
      });

    trackClickUploadMore('people', currentEvent, position);
  };

  const handleDemoPeople = (id, isAdd) => {
    const people = [...demoPeople];
    const foundPerson = people.find((person) => person.face.elementId === id);

    foundPerson.isSelected = isAdd;

    if (!noPeople) setPeople(getDemoSelected(people));

    setDemoPeople(people);
    localStorage.setItem('demoData', JSON.stringify({ ...demoData, people }));
  };

  const filteredUploading = useMemo(() => {
    const peopleIds = people.map((person) => person.face.elementId);

    let filteredUploading;

    const selectedDemoPeople = getDemoSelected(demoPeople);

    // demo event
    if (isDemo && isUploading) {
      filteredUploading = [...selectedDemoPeople]
        .filter((p) => p.face && !peopleIds.includes(p.face.elementId))
        .map((p) => {
          return { ...p, isUploading: true };
        });
    }

    // usual event
    else
      filteredUploading = Object.keys(uploadingPeople)
        .map((id) => uploadingPeople[id])
        .filter((f) => f.face && !peopleIds.includes(f.face.parentElementId))
        .filter((f) => !deletingPeople.includes(f.face.parentElementId));

    if (isEmpty(filteredUploading) && isUploading) {
      // clear uploading states
      setUploadingPeople({});
      setIsUploading(false);

      toast.success('All people are uploaded!', {
        toastId: 'people-upload-success',
      });

      localStorage.setItem(
        'uploadData',
        JSON.stringify({
          ...JSON.parse(localStorage.getItem('uploadData')),
          peopleCount: isDemo ? selectedDemoPeople.length : people.length,
        }),
      );

      // for demo – track people upload with num of selected people
      if (isDemo) trackUploadDemo('people', selectedDemoPeople.length);
      else {
        // send email to the user that the upload is complete
        if (!isPublic)
          sendStepCompleteEmail(user, currentEvent, 'People upload');

        trackUploadBatchSuccess('people', eventId);
      }
    }

    return filteredUploading;
  }, [
    currentEvent,
    deletingPeople,
    demoPeople,
    eventId,
    isDemo,
    isPublic,
    isUploading,
    people,
    uploadingPeople,
    user,
  ]);

  const isFilteredUploading = !isEmpty(filteredUploading);

  const noPeople = useMemo(() => {
    return (isEmpty(people) && !isFilteredUploading) || showUpload;
  }, [people, filteredUploading, showUpload]);

  const allowUploadMore = useMemo(() => {
    return !isDemo || !demoData.isLimited;
  }, [demoData.isLimited, isDemo]);

  const { heading, subheading, tipText, infoPoints, generatedText } =
    getPeopleText({
      people,
      noPeople,
      isUploading: isFilteredUploading,
      isDemo,
      eventId,
      handleUploadMore,
      demoData,
      isPublic,
    });

  return (
    <PageSpinner
      isLoading={isLoading}
      pageError={pageError}
      title={`People – ${currentEvent?.name}`}
      isPageContainer
    >
      <BackBtn />

      <PageWithSideBar
        mainContent={
          <>
            <PageHeader heading={heading} subheading={subheading} />

            {noPeople ? (
              // upload people view
              <React.Fragment>
                <DemoComponent
                  setPeople={setPeople}
                  handleDemoPeople={handleDemoPeople}
                  handleUploadStart={handleUploadStart}
                />

                <DraggerSimplified
                  type="image"
                  isMultiple
                  onDrop={handleUploadPerson}
                  uploadRef={uploadRef}
                  isDemo={isDemo}
                />
              </React.Fragment>
            ) : !isEmpty(people) || isFilteredUploading ? (
              // uploading/uploaded people view
              <React.Fragment>
                {!isFilteredUploading && !isDemo && (
                  <Switch
                    label="Details"
                    checked={isShowDetails}
                    onClick={() => {
                      setIsShowDetails(!isShowDetails);
                      trackPeopleToggleDetails(!isShowDetails);
                    }}
                  />
                )}

                {/* people summary */}

                <PeopleSummary
                  event={currentEvent}
                  people={newPeople}
                  uploadingPeople={filteredUploading}
                  deletingPeople={deletingPeople}
                  setDeletingPeople={setDeletingPeople}
                  fetchPeopleData={fetchPeopleData}
                  isShowDetails={isShowDetails}
                  isDemo={isDemo}
                  handleDemoPeople={handleDemoPeople}
                />

                {!isEmpty(generatedPeople) && (
                  <React.Fragment>
                    <PageSubheader
                      heading="Already generated"
                      subheading={generatedText}
                    />

                    <PeopleSummary
                      event={currentEvent}
                      people={generatedPeople}
                      deletingPeople={deletingPeople}
                      setDeletingPeople={setDeletingPeople}
                      fetchPeopleData={fetchPeopleData}
                      isShowDetails={isShowDetails}
                      isDemo={isDemo}
                      handleDemoPeople={handleDemoPeople}
                    />
                  </React.Fragment>
                )}

                {!isFilteredUploading && (
                  <React.Fragment>
                    <div className="flex flex-col sm:flex-row gap-4">
                      <DoneBtn addClass={allowUploadMore ? 'sm:w-1/2' : ''} />

                      {allowUploadMore && (
                        <UploadMoreBtn
                          onClick={() => handleUploadMore('Bottom button')}
                        >
                          Check-In More People
                        </UploadMoreBtn>
                      )}
                    </div>
                  </React.Fragment>
                )}
              </React.Fragment>
            ) : null}
          </>
        }
        sideContent={
          <>
            {!isDemo && <SellingPoint text={tipText} />}

            <UploadInfoPoints
              points={infoPoints}
              isInfo={!noPeople && !isFilteredUploading}
            />
          </>
        }
        showDemoTipText={
          isDemo
            ? noPeople || isFilteredUploading
              ? 'upload'
              : 'summary'
            : null
        }
      />

      <ErrorModal
        show={!!error}
        heading="Something went wrong"
        subheading={error}
        isTryAgainBtn
      />
    </PageSpinner>
  );
};

export default People;
