import React, { useState } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { PiDownloadSimple, PiShareFat } from 'react-icons/pi';
import { toast } from 'react-toastify';

import { isEmpty } from '../../Utils';

import {
  trackCreationClickDownload,
  trackCreationDownloadSuccess,
  trackCreationDownloadError,
  trackCreationClickNativeShare,
  trackCreationNativeShareSuccess,
  trackCreationNativeShareError,
} from '../../analytics';

import { Spinner, Button } from '../../components';

import { getGeneratedVideo } from '../../services/api/video';
import {
  incrementDownloadedMetric,
  incrementSharedMetric,
} from '../../services/api/videoCreationMetrics';

import withRouter from '../../withRouter';

import CaptionModal from './captionModal';

const isTouchDevice = isMobile || isTablet;

const VideoBtnsContainer = (props) => {
  const { event, person, videoUrl, onClickRemoveWatermark } = props;

  const [savedFile, setSavedFile] = useState(null);
  const [isDownloadingDevice, setIsDownloadingDevice] = useState(false);
  const [isDownloadingBrowser, setIsDownloadingBrowser] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);

  if (isEmpty(person)) return null;

  // download video to the device memory
  const handleDownload = async (withoutTracker = false) => {
    if (!withoutTracker) trackCreationClickDownload('device');

    try {
      fetch(videoUrl)
        .then((response) => {
          setIsDownloadingDevice(true);
          return response.blob();
        })
        .then(async (blob) => {
          setIsDownloadingDevice(false);
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = `CrowdClip ${person.face.name} video.mp4`;
          a.click();
          window.URL.revokeObjectURL(url);
          incrementDownloadedMetric(person.metrics.metricId);

          if (!withoutTracker) trackCreationDownloadSuccess('device');
        });
    } catch (error) {
      console.error(error);

      if (!withoutTracker) trackCreationDownloadError('device');
    }
  };

  // show error toast and start video downloading
  const handleSharingError = () => {
    handleDownload(true);

    toast.error(
      'Unfortunately, the video was not shared successfully. As a workaround, the video can be downloaded into the device, ready for you to share.',
    );
  };

  // download video to browser storage
  const handleBrowserDownload = async () => {
    if (savedFile === null) {
      setIsDownloadingBrowser(true);
      trackCreationClickDownload('browser');

      try {
        const res = await getGeneratedVideo(person.creations[0].elementId);
        const currentDate = new Date().valueOf();

        const newFile = new File([res], `crowdclip_${currentDate}.mp4`, {
          type: res.type,
        });
        setSavedFile(newFile);
        trackCreationDownloadSuccess('browser');

        if (!!event.caption) setShowShareModal(true);
        else handleNativeShare('No caption', newFile);
      } catch (err) {
        console.error(err);
        handleSharingError();
        trackCreationDownloadError('browser');
      }

      setIsDownloadingBrowser(false);
    } else {
      if (!!event.caption) setShowShareModal(true);
      else handleNativeShare('No caption');
    }
  };

  // share video via native share
  const handleNativeShare = async (type, newFile) => {
    const file = newFile ?? savedFile;

    try {
      trackCreationClickNativeShare();

      if (file !== null) {
        if (!navigator.share) {
          console.error(
            'Browser does not support sharing, please download the file and share manually.',
          );
          handleSharingError();
          trackCreationNativeShareError();
          return;
        }

        if (!navigator.canShare({ files: [file] })) {
          console.error(
            'File cannot be shared, please download the file and share manually.',
          );
          handleSharingError();
          trackCreationNativeShareError();
          return;
        }

        await navigator
          .share({
            text: 'Check out this video',
            files: [file],
          })
          .catch((error) => {
            // AbortError – user closed the sharing window
            if (!error.toString().includes('AbortError')) {
              console.error('Error sharing:', error);
              handleSharingError();
              trackCreationNativeShareError();
            }
          });

        incrementSharedMetric(person.metrics.metricId);

        trackCreationNativeShareSuccess(type);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const btnClass =
    'group flex items-center justify-center p-2.5 font-bold text-[1.25rem] rounded-md hover:bg-black hover:text-white disabled:opacity-65';

  return (
    <div className="flex flex-col sm:flex-row gap-2">
      <div className="flex justify-end">
        <button
          className={btnClass}
          onClick={handleDownload}
          disabled={isDownloadingDevice}
        >
          {isDownloadingDevice ? (
            <Spinner size="sm" hoverColor="white" />
          ) : (
            <PiDownloadSimple />
          )}
        </button>

        {isTouchDevice && (
          <button
            className={btnClass}
            onClick={handleBrowserDownload}
            disabled={isDownloadingBrowser}
          >
            {isDownloadingBrowser ? (
              <Spinner size="sm" hoverColor="white" />
            ) : (
              <PiShareFat />
            )}
          </button>
        )}
      </div>

      {!event?.paid && !person?.face?.paid && (
        <Button isThin onClick={onClickRemoveWatermark}>
          Remove Watermark
        </Button>
      )}

      <CaptionModal
        show={showShareModal}
        onHide={() => setShowShareModal(false)}
        event={event}
        handleNativeShare={handleNativeShare}
      />
    </div>
  );
};

export default withRouter(VideoBtnsContainer);
