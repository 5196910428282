import React from 'react';
import classNames from 'classnames';

export const Button = (props) => {
  const { children, type, variant, className, isThin, onClick, disabled } =
    props;

  const btnClass = classNames(
    {
      'flex items-center justify-center rounded-md transition-all disabled:pointer-events-none': true,
      'px-3 py-1.5 disabled:opacity-65':
        variant !== 'square-icon' && variant !== 'link',
      'min-w-32 h-[var(--form-el-height)]':
        !isThin && variant !== 'square-icon' && variant !== 'link',
      'h-10': isThin,
      'bg-primary-900 text-white hover:opacity-80':
        !variant || variant === 'primary',
      'bg-gray-500 text-white hover:opacity-80': variant === 'secondary',
      'bg-primary-50 text-primary-900 border-1 border-primary-translucent border-solid hover:border-primary-900':
        variant === 'pink',
      'border-1 border-grey-200 border-solid hover:border-primary-900':
        variant === 'outline',
      'bg-grey-100 text-black hover:bg-grey-200': variant === 'light-grey',
      'w-10 h-10 bg-grey-100 text-[1.25rem] text-primary-900 hover:bg-gradient-primary-bottom hover:text-white disabled:opacity-40':
        variant === 'square-icon',
      'bg-blue-green text-white hover:opacity-80': variant === 'blue-green',
      'bg-white text-primary-900 hover:opacity-80': variant === 'white',
      'text-true-white hover:bg-white hover:text-primary-900':
        variant === 'transparent-white',
      'inline-block p-0 underline cursor-pointer text-primary-900 hover:opacity-60':
        variant === 'link',
    },
    className,
  );

  return (
    <button
      type={type ?? 'button'}
      className={btnClass}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
